import { User } from '@firebase/auth'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Claims = {
  store_id: number
  role: 'admin' | 'agent'
}

declare module '@firebase/auth' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ParsedToken extends Claims {}
}

type AuthState = {
  user: User | null
  claims: Claims | null
}

const initialState: AuthState = {
  user: null,
  claims: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<AuthState['user']>) => {
      state.user = action.payload
    },
    updateClaim: (state, action: PayloadAction<AuthState['claims']>) => {
      state.claims = action.payload
    },
    reset: () => initialState
  }
})

export const { updateUser, updateClaim, reset } = authSlice.actions
