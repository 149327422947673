import { useRouter } from 'next/dist/client/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SnackBarCard } from 'src/lib/ui/snack-bar-card'
import { instantSerialize } from 'src/lib/utils/serialize'
import * as applicationsStore from 'src/store/applications'
import * as authStore from 'src/store/auth'
import { updateClaim, updateUser } from 'src/store/auth'
import { listenAuthState, signOut } from '../api/firebase-auth'

type Props = {
  children?: React.ReactNode
}

export const Auth: React.VFC<Props> = ({ children }) => {
  const router = useRouter()
  const userSelector = useSelector((state) => state.auth.user)
  const claimsSelector = useSelector((state) => state.auth.claims)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const listen = listenAuthState(async (user) => {
      if (!user) {
        if (userSelector) {
          dispatch(authStore.reset())
          dispatch(applicationsStore.reset())
          SnackBarCard.fire({
            title: 'ログアウトしました。'
          })
          return
        }

        const query = router.query.signedOut
          ? undefined
          : {
              redirectTo: router.asPath
            }

        await router.replace({
          pathname: '/sign-in',
          query
        })
        return
      }

      if (user && !userSelector) {
        dispatch(updateUser(instantSerialize(user)))

        if (!claimsSelector) {
          const result = await user.getIdTokenResult()

          switch (result.claims.role) {
            case 'admin': {
              const uid = prompt('uid?')
              router.replace({
                ...router,
                pathname: '/sign-in/admin',
                query: {
                  ...router.query,
                  adminIdToken: result.token,
                  uid
                }
              })
              break
            }
            case 'agent': {
              dispatch(updateClaim(result.claims))
              break
            }
            default: {
              signOut()
              throw new Error(`Invalid role ${result.claims.role}`)
            }
          }
        }
      }
    })

    return listen
  }, [claimsSelector, dispatch, router, userSelector])

  const authorized = userSelector

  return <React.Fragment>{authorized && children}</React.Fragment>
}
