/* eslint-disable @typescript-eslint/no-empty-interface */
import { configureStore } from '@reduxjs/toolkit'
import { applicationsSlice } from './applications'
import { authSlice } from './auth'
import { smoothBaseApi } from './smooth-base-api'

export const reducer = {
  [smoothBaseApi.reducerPath]: smoothBaseApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [applicationsSlice.name]: applicationsSlice.reducer
}

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(smoothBaseApi.middleware)
})

type AppState = ReturnType<typeof store.getState>
type Dispatch = typeof store.dispatch

declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
  export function useDispatch(): Dispatch
}
