import { useSelector } from 'react-redux'

export const useCurrentStoreId = () => {
  const storeIdSelector = useSelector(
    (state) => state.auth.claims?.['store_id']
  )

  return {
    storeId: storeIdSelector as number
  }
}
