import { createApi } from '@reduxjs/toolkit/query/react'
import { ParsedUrlQueryInput, stringify } from 'querystring'
import { getIdToken } from 'src/features/auth'
import { errorCallback } from 'src/features/errors'
import { HttpError } from 'src/features/errors/utils/custom-errors'
import { CamelCase, camelCase } from 'src/lib/aikagi'
import { axios } from 'src/lib/axios'
import { SMOOTH_PARTNER_API_URL } from 'src/lib/constants/value'

export type Pagination = {
  page: number
  limit: number
  prev: number
  next: number
  count: number
}

type StoreRes = {
  data: {
    id: number
    company_id: number
    name: string
    address: string
    email: string
    business_hours_open: number
    business_hours_close: number
    business_hours_special: string[]
    postal_code: string
    prefecture: string
    access: string
    holiday: string[]
    description: string
    line_description: string
    hp_url: string
    google_map_url: string
    enabled: true
    created_at: string
    updated_at: string
    telephone_number: string
    is_center: boolean
    contact_ways: ('line' | 'email')[] | string
  }
}

type StoresRes = {
  data: StoreRes['data'][]
}

type ProposalRes = {
  data: {
    id: number
    user_id: number
    store_id: number
    introduction_status: number
    search_status: number
    application_status: number
    memo: string
    version: number
    enabled: boolean
    created_at: string
    updated_at: string
    gardia_transaction_code: string
    propose_frequency: string
  }
}

export const APPLICATION_STATUSES = {
  NEVER: 'never',
  WIP: 'wip',
  DONE: 'done',
  REJECT: 'reject',
  CANCEL: 'cancel'
} as const

export type ApplicationStatus =
  typeof APPLICATION_STATUSES[keyof typeof APPLICATION_STATUSES]

type ApplicationRes = {
  data: {
    id: number
    proposal_id: number
    house_name: string
    room_name: string
    rent_price: number
    ad_price: number
    initial_cost: number
    total_initial_cost: number
    rough_estimate_initial_cost: number
    application_status: ApplicationStatus
    version: number
    application_on: string
    moving_target_on: string
    payment_initial_cost_on: string
    moving_on: string
    enabled: boolean
    address: string
    store_contracted_on: string
    lendable_loan: number
    property_id: number
    created_at: string
    updated_at: string
  }
}

type ApplicationsRes = Pagination & {
  data: (Application & {
    customer_id: number
  })[]
}

type SearchConditionRes = {
  data: {
    id: number
    prefecture: string
    rent_lower: number
    rent_upper: number
    rent_include: number
    moving_target_on: string
    tab: unknown
    breadth_lower: number
    breadth_upper: number
    build_const: string
    build_type: string | string[]
    building_age: number
    madori: string
    ambiguous: unknown
    route_line: string[]
    walk: unknown
    stations: string[]
    target_cities: string[]
    target_pref: string
    requirement: string
    special: string[]
    house_urls: string[]
    moving_purpose: string
    created_at: string
    updated_at: string
  }
}

type CustomerRes = {
  data: {
    id: number
    email: string
    status: number
    first_name: string
    last_name: string
    first_furigana: string
    last_furigana: string
    line_user_name: string
    address: string
    icon_src: string
    gender: string
    birthday: string
    enabled: true
    tracking_id: number
    line_profile_synced_at: string
    status_message: string
    telephone_number: string
    created_at: string
    updated_at: string
  }
}

type ExaminationRes = {
  data: {
    id: number
    user_id: number
    proposal_id: number
    current_residence_status: string
    current_house_rent: number
    child_count: number
    household: number
    max_loan_amount: number
    family_type: string
    income_yearly: number
    income_monthly: number
    bonus_yearly: number
    has_loan: true
    loan_total: number
    loan_monthly: number
    job: string
    job_term: number
    has_credit: true
    credit_line: number
    had_arrears: true
    company_name: string
    company_postal_code: string
    company_address: string
    company_telephone_number: string
    user_antisocial_examination: string
    user_bankruptcy_examination: string
    company_antisocial_examination: string
    company_bankruptcy_examination: string
    company_fact_examination: string
    payday: number
    enabled: true
    loan_purpose: string
    rent_subsidy: string
    loan_purpose_other: string
    gardia_result: 1 | 0
    house_mates: {
      house_mates_relationship: string
      house_mates_income: number
    }[]
    job_description: string
    company_name_kana: string
    number_of_payment: number
    annual_interest_rate: number
    created_at: string
    updated_at: string
  }
}

export const ATTACHMENT_FILE_TYPES = {
  FIXED_INITIAL_COST_DOCUMENT: 'fixed_initial_cost_document',
  MAISOKU_DOCUMENT: 'maisoku_document',
  APPLICATION_DOCUMENT: 'application_document'
} as const

export type AttachmentFileType =
  typeof ATTACHMENT_FILE_TYPES[keyof typeof ATTACHMENT_FILE_TYPES]

export const ATTACHMENT_FILE_TYPES_JP = {
  [ATTACHMENT_FILE_TYPES.MAISOKU_DOCUMENT]: 'お部屋の図面',
  [ATTACHMENT_FILE_TYPES.FIXED_INITIAL_COST_DOCUMENT]: '確定初期費用書',
  [ATTACHMENT_FILE_TYPES.APPLICATION_DOCUMENT]: '入居申込書'
} as const

type AttachmentsRes = {
  data: {
    id: number
    user_id: number
    file_data: string
    file_name: string
    file_extention: string
    file_type: AttachmentFileType
    file_type_jp: string
    s3_bucket_key_path: string
    full_path: string
    is_confirmed: true
    enabled: boolean
    status: string
    created_at: string
    updated_at: string
  }[]
}

type PropertiesRes = Pagination & {
  data: {
    id: number
    name: string
    building_name: string
    rent_fee: number
    room_number: string
    url: string
    created_at: string
    updated_at: string
  }[]
}

type SchedulesRes = {
  data: {
    id: number
    enabled: boolean
    information: string
    is_done: boolean
    priority: number
    schedule_type: string
    scheduled_at: string
    status: string
    proposal_id: number
    store_id: number
    user_id: number
    created_at: string
    updated_at: string
  }[]
}

type StoreQueryRes = CamelCase<StoreRes>
type StoresQueryRes = CamelCase<StoresRes>
type ProposalQueryRes = CamelCase<ProposalRes>
type ApplicationQueryRes = CamelCase<ApplicationRes>
type ApplicationsQueryRes = CamelCase<ApplicationsRes>
type SearchConditionQueryRes = CamelCase<SearchConditionRes>
type CustomerQueryRes = CamelCase<CustomerRes>
type ExaminationQueryRes = CamelCase<ExaminationRes>
type AttachmentsQueryRes = CamelCase<AttachmentsRes>
type PropertiesQueryRes = CamelCase<PropertiesRes>
type SchedulesQueryRes = CamelCase<SchedulesRes>

type ExtractData<T> = T extends { data: infer D } ? D : never

export type Store = ExtractData<StoreQueryRes>
export type Stores = ExtractData<StoresQueryRes>
export type Proposal = ExtractData<ProposalQueryRes>
export type Application = ExtractData<ApplicationQueryRes>
export type Applications = ExtractData<ApplicationsQueryRes>
export type SearchCondition = ExtractData<SearchConditionQueryRes>
export type Customer = ExtractData<CustomerQueryRes>
export type Examination = ExtractData<ExaminationQueryRes>
export type Attachments = ExtractData<AttachmentsQueryRes>
export type Properties = ExtractData<PropertiesQueryRes>
export type Schedules = ExtractData<SchedulesQueryRes>

const baseQuery = async (args: string) => {
  try {
    const idToken = await getIdToken()
    if (!idToken) throw new Error('No idToken')

    const response = await axios.get(`${SMOOTH_PARTNER_API_URL}${args}`, {
      headers: {
        Authorization: `Token ${idToken}`
      }
    })

    const data = camelCase(response.data)

    return { data }
  } catch (e) {
    const reason = String(e)
    const error = new HttpError(reason)
    await errorCallback(error)
    return { error }
  }
}

export const smoothBaseApi = createApi({
  baseQuery,
  endpoints: (builder) => ({
    getStore: builder.query<StoreQueryRes, number>({
      query: (storeId) => `/stores/${storeId}`
    }),
    getCompaniesStores: builder.query<StoresQueryRes, { companyId: number }>({
      query: ({ companyId }) => `/companies/${companyId}/stores`
    }),
    getStoresApplications: builder.query<
      ApplicationsQueryRes,
      {
        storeId: number
        params: ParsedUrlQueryInput
      }
    >({
      query: ({ storeId, params }) =>
        `/stores/${storeId}/applications?${stringify(params)}`
    }),
    getProposal: builder.query<
      ProposalQueryRes,
      {
        storeId: number
        proposalId: number
      }
    >({
      query: ({ storeId, proposalId }) =>
        `/stores/${storeId}/proposals/${proposalId}`
    }),
    getApplication: builder.query<
      ApplicationQueryRes,
      {
        storeId: number
        applicationId: number
      }
    >({
      query: ({ storeId, applicationId }) =>
        `/stores/${storeId}/applications/${applicationId}`
    }),
    getSearchCondition: builder.query<
      SearchConditionQueryRes,
      {
        storeId: number
        applicationId: number
      }
    >({
      query: ({ storeId, applicationId }) =>
        `/stores/${storeId}/applications/${applicationId}/search_condition`
    }),
    getCustomer: builder.query<
      CustomerQueryRes,
      { customerId: number; storeId: number }
    >({
      query: ({ customerId, storeId }) =>
        `/stores/${storeId}/customers/${customerId}`
    }),
    getExamination: builder.query<
      ExaminationQueryRes,
      { applicationId: number; storeId: number }
    >({
      query: ({ applicationId, storeId }) =>
        `/stores/${storeId}/applications/${applicationId}/examination`
    }),
    getAttachments: builder.query<
      AttachmentsQueryRes,
      { applicationId: number; storeId: number }
    >({
      query: ({ applicationId, storeId }) =>
        `/stores/${storeId}/applications/${applicationId}/attachments`
    }),
    getProperties: builder.query<
      PropertiesQueryRes,
      { applicationId: number; storeId: number; params: ParsedUrlQueryInput }
    >({
      query: ({ applicationId, storeId, params }) =>
        `/stores/${storeId}/applications/${applicationId}/properties?${stringify(
          params
        )}`
    }),
    getSchedules: builder.query<
      SchedulesQueryRes,
      { applicationId: number; storeId: number; params: ParsedUrlQueryInput }
    >({
      query: ({ applicationId, storeId, params }) => {
        const { schedule_type, ...exp } = params

        return `/stores/${storeId}/applications/${applicationId}/schedules?${stringify(
          { schedule_type },
          '&',
          '[]='
        )}&${stringify(exp)}`
      }
    })
  })
})

export const extractData = <T extends { data: D } | undefined, D>(args: T) => {
  if (typeof args?.data === 'object' && args.data) {
    return args.data as ExtractData<T>
  }
  return undefined
}

export const extractPagination = <T extends Pagination | undefined>(
  args: T
) => {
  if (!args) return undefined

  return {
    page: args.page,
    limit: args.limit,
    prev: args.prev,
    next: args.next,
    count: args.count
  }
}

export const {
  useGetApplicationQuery,
  useGetStoresApplicationsQuery,
  useGetSearchConditionQuery,
  useGetCustomerQuery,
  useGetExaminationQuery,
  useGetAttachmentsQuery,
  useGetPropertiesQuery,
  useGetStoreQuery,
  useGetProposalQuery,
  useGetSchedulesQuery,
  useGetCompaniesStoresQuery
} = smoothBaseApi
