import LogRocket from 'logrocket'
import { LOGROCKET_ORG } from '../constants/value'

export const initLogRocket = () => {
  if (process.env.ENV_NAME === 'local') return
  LogRocket.init(LOGROCKET_ORG)
}

export const identifyLogRocketUser = (
  id: string,
  params: Record<string, string>
) => {
  if (process.env.ENV_NAME === 'local') return
  LogRocket.identify(id, params)
}
