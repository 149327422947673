// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { ENV_NAME, SENTRY_DSN as CONSTANTS } from 'src/lib/constants'

const SENTRY_DSN =
  CONSTANTS || process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  environment: ENV_NAME,
  dsn:
    SENTRY_DSN ||
    'https://fba5c94113d5458a9d65af49179d246f@o529634.ingest.sentry.io/6074679',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
