import { ENV_NAME, SENTRY_DSN } from 'src/lib/constants'

export const errorCallback = async (err: Error) => {
  const sentry = await import('@sentry/nextjs')

  sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV_NAME
  })

  sentry.captureException(err)
}
