import { ENV_NAMES } from './values'

type EnvName = typeof ENV_NAMES[keyof typeof ENV_NAMES]

const getEnvName = (): EnvName => {
  const envName = ENV_NAMES[process.env.ENV_NAME as keyof typeof ENV_NAMES]
  if (envName) return envName
  throw new Error('wrong env name: ' + process.env.ENV_NAME)
}

export const ENV_NAME = getEnvName()
