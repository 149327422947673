import { ENV_NAME } from './envName'
import * as CONSTANTS_VALUES from './values'

// Sentry
export const SENTRY_DSN =
  'https://fba5c94113d5458a9d65af49179d246f@o529634.ingest.sentry.io/6074679' as const
// Smooth Partner Base API
export const SMOOTH_PARTNER_API_URL =
  CONSTANTS_VALUES.SMOOTH_PARTNER_API_URLS[ENV_NAME]
// LOGROCKET_ORG Id
export const LOGROCKET_ORG = CONSTANTS_VALUES.LOGROCKET_ORGS[ENV_NAME]
// Firebaseの情報
export const FIREBASE_PROPS = CONSTANTS_VALUES.FIREBASE_PROPS[ENV_NAME]
